import { useState } from "react";

export function useVerificationHook() {
  const STEPS = ["Transition details", "Processing", "Confirmation"];

  const [activeStep, setActiveStep] = useState(0);
  const [locationCode, setLocationCode] = useState("");

  const onNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const onResetSteps = () => {
    setActiveStep(0);
  };

  const onInputChange = (e) => {
    const value = e.target.value;
    switch (e.target.name) {
      case "locationCode":
        setLocationCode(value);
        break;
      default:
        return;
    }
  };

  return [
    {
      STEPS,
      activeStep,
      locationCode,
      onNextStep,
      onResetSteps,
      onInputChange,
    },
  ];
}

export function useProcessingStepHook() {
  const PSTEPS = ["Screens", "Media Players", "New Prime Virtual IP", "Done"];

  const [activePStep, setActivePStep] = useState(0);

  const onNextPStep = (nextStep) => {
    setActivePStep(nextStep);
  };

  const onResetPSteps = () => {
    setActivePStep(0);
  };

  return [{ PSTEPS, activePStep, onNextPStep, onResetPSteps }];
}
