import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
// import DashboardIcon from "@material-ui/icons/Dashboard";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import DevicesIcon from "@material-ui/icons/Devices";

import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Drawer,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: 300,
  },
}));

export default function AppDrawerComponent(props) {
  const classes = useStyles();
  const { toggleDrawer, drawerOpen } = props;
  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
        {/* <ListItem button key={1} component={Link} to="/">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary={"Dashboard"} />
        </ListItem> */}
        <ListItem button key={1} component={Link} to="/">
          <ListItemIcon>
            <VerifiedUserIcon />
          </ListItemIcon>
          <ListItemText primary={"Verification"} />
        </ListItem>
        <ListItem button key={2} component={Link} to="/devices">
          <ListItemIcon>
            <DevicesIcon />
          </ListItemIcon>
          <ListItemText primary={"Devices"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Drawer
      open={drawerOpen}
      onClose={toggleDrawer}
      className={classes.drawer}
      classes={{ paper: classes.drawerPaper }}
    >
      {sideList()}
    </Drawer>
  );
}
