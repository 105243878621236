import React from "react";
import ReactDOM from "react-dom";
import App from "./app/app";
import { Auth0Provider } from "./app/app-auth.provider";
import { getClientCode } from "./helper";
import { Config } from "./helper/config.helper";

const clientCode = getClientCode();
console.log(clientCode);
const config = Config.clientSettings[clientCode];
console.log(config);
(() => {
  if (!config) {
    console.error("Error when loading the application. Invalid config!");
    return;
  }

  ReactDOM.render(
    <Auth0Provider
      domain={config.domain}
      client_id={config.clientId}
      redirect_uri={window.location.origin}
      audience={config.audience}
      config={config}
    >
      <App />
    </Auth0Provider>,
    document.getElementById("root")
  );
})();
