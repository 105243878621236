import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useTransitionHook } from "./transition-step.hook";
import { ErrorDialogComponent } from "../../components/error-dialog.component";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  spaceInputs: {
    marginBottom: "20px",
  },
}));

export function TransitionStepComponent({ context }) {
  const classes = useStyles();
  const { onNextStep, locationCode, onInputChange } = context;

  const [{ isProcessing, formErrors, transitionErrors, setTransitionErrors }] =
    useTransitionHook();

  const getError = (fieldName) => {
    return formErrors[fieldName] ? true : false;
  };

  const getHelperText = (fieldName) => {
    return formErrors[fieldName] || "";
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    onNextStep();
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            id="locationCode"
            name="locationCode"
            label="Location Code"
            error={getError("locationCode")}
            helperText={getHelperText("locationCode")}
            onChange={onInputChange}
            fullWidth
            value={locationCode}
            className={classes.spaceInputs}
            variant="outlined"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isProcessing}
          >
            {isProcessing ? "Processing..." : "Proceed"}
          </Button>
        </Grid>
      </Grid>
      <ErrorDialogComponent
        isOpen={transitionErrors !== null}
        title="Error"
        content={transitionErrors}
        closeHandler={() => setTransitionErrors(null)}
      />
    </form>
  );
}
