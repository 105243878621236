import React from "react";
import { HashRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import { CssBaseline } from "@material-ui/core";
import { useAuth0 } from "./app-auth.provider";
import AppPages from "./app-pages.router";
import CopyrightComponent from "../components/copyright.component";
import AppBarComponent from "./app-bar.component";
import AppDrawerComponent from "./app-drawer.component";

const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
});

export default function App() {
  const { loading } = useAuth0();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <>
          <CssBaseline />
          <AppBarComponent toggleDrawer={toggleDrawer} />
          <AppDrawerComponent
            drawerOpen={drawerOpen}
            toggleDrawer={toggleDrawer}
          />
          <main>
            <AppPages />
            <CopyrightComponent />
          </main>
        </>
      </HashRouter>
    </ThemeProvider>
  );
}
