const getClientSettings = (rawClientSettings) => {
  if (!rawClientSettings) {
    const error = `ConfigHelper: Not provided env var REACT_APP_CLIENT_SETTINGS`;
    console.error(`${error} - ${rawClientSettings}`);
    throw new Error(error);
  }

  return JSON.parse(rawClientSettings);
};

const Config = {
  isProduction: process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV.toUpperCase() === "PROD" : false,
  apiUrl: process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST : "",
  clientSettings: getClientSettings(process.env.REACT_APP_CLIENT_SETTINGS),
  permittedLSRoles:
    process.env.REACT_APP_LS_ROLES.split(",").map((c) => c.trim()) || [],
};

export { Config };
