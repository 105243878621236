export function getClientCode() {
  const savedClientCode = localStorage.getItem("CLIENT_CODE");
  console.log('HOST', window.location.hostname);
  if (!savedClientCode) {
    const host = window.location.hostname;
    let [clientCode] = host.split(".");
    clientCode = clientCode.replace("-", ".");
    console.log(`Client Code outside of if statement: ${clientCode} for ${host}`);
    if (
      clientCode === "localhost" ||
      host.endsWith("amazonaws.com") ||
      host.endsWith("cloudfront.net") ||
      host.includes("qa-coates.io")
    ) {
      clientCode = "ca-mcd-cutover.qa-coates.io";
    }
    localStorage.setItem("CLIENT_CODE", clientCode);
    return clientCode;
  }
  return savedClientCode;
}

export function getReturnToUrl() {
  const {
    location: { protocol, host },
  } = window;
  return `${protocol}//${host}`;
}
