import { useState } from "react";
import { TransitionService } from "../../services/transition.service";
import { useAuth0 } from "../../app/app-auth.provider";

export function useTransitionHook() {
  const { logout } = useAuth0();

  const [isProcessing, setIsProcessing] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [transitionErrors, setTransitionErrors] = useState(null);

  const validateForm = (locationCode) => {
    if (!locationCode) {
      setFormErrors({ locationCode: `Location Code is required` });
      return;
    }

    setFormErrors({});
    return true;
  };

  const transitionDevice = async ({ locationCode, taskID }) => {
    console.log("transitionDevice taskID: ", locationCode, taskID);
    const isFormValid = validateForm(locationCode);

    if (!isFormValid) {
      return;
    }
    try {
      setIsProcessing(true);
      const transitionService = new TransitionService(logout);
      const jobStatus = await transitionService.transitionDevice({
        locationCode,
        taskID,
      });
      return jobStatus;
    } catch (e) {
      const errorMessage = `An error occurred while transitioning the device: ${e.message}. Please refresh the page and try again.`;
      setTransitionErrors(errorMessage);
      return;
    } finally {
      setIsProcessing(false);
    }
  };

  return [
    {
      isProcessing,
      formErrors,
      transitionDevice,
      transitionErrors,
      setTransitionErrors,
    },
  ];
}
