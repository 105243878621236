import React from "react";
import { green } from "@material-ui/core/colors";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircle from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
  checkCircleColor: {
    fill: green[500],
  },
  alignCenter: {
    textAlign: "center",
  },
}));

export function ConfirmationStepComponent({ context }) {
  const classes = useStyles();
  const { onResetSteps } = context;
  return (
    <Box flexDirection="column" alignItems="center" justifyItems="center">
      <div className={classes.alignCenter}>
        <CheckCircle
          style={{ fontSize: 80 }}
          className={classes.checkCircleColor}
        />
        <Typography variant="h5" gutterBottom>
          Network Transition Complete.
        </Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          style={{ marginTop: 20 }}
          onClick={() => {
            onResetSteps();
          }}
        >
          OK
        </Button>
      </div>
    </Box>
  );
}
