import { checkIfTokenExpired, logoutUser } from "../helper/auth.helper";
import axios from "axios";
import { Config } from "../helper/config.helper";

export class TransitionService {
  url;
  constructor(logoutFn) {
    this.url = Config.apiUrl;
    this.setAuthHeader(logoutFn);
  }

  setAuthHeader(logoutFn) {
    const isTokenExpired = checkIfTokenExpired();
    console.log("isTokenExpired: ", isTokenExpired);
    if (isTokenExpired) {
      console.log("Token expired: redirecting user to login");
      logoutUser(logoutFn);
      return;
    }
    const token = localStorage.getItem("token");
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  async transitionDevice({ locationCode, taskID }) {
    try {
      const responseTransition = await axios.post(
        `${this.url}/step/${taskID}`,
        {
          location: locationCode,
        }
      );

      console.log(responseTransition);

      let { status } = responseTransition.data;
      //comment out to implement refactor for stepper component
      // if (status) {
      //   if (step === 1 || step === 2) {
      //     status = await this.transitionDevice({
      //       locationCode,
      //       step: step + 1,
      //     });
      //   }
      // }

      return status;
    } catch (e) {
      if (!e.response) {
        throw new Error(e);
      }
      const { error } = e.response.data;
      throw new Error(error);
    }
  }
}
