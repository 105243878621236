import React from 'react';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export default function CopyrightComponent() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: 20}}>
      {'Copyright © '}
      <Link  href="https://coatesgroup.com/">
        Coates Group
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}