import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  AppBar,
  Toolbar,
  MenuItem,
  Menu,
  Typography,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { useAuth0 } from "./app-auth.provider";
import { logoutUser } from "../helper";

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    width: 300,
  },
}));

export default function AppBarComponent(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { logout } = useAuth0();

  const onMenu = (event) => setAnchorEl(event.currentTarget);
  const onClose = () => setAnchorEl(null);

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          Coates Network Transition Tool
        </Typography>
        <div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={onMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={onClose}
          >
            <MenuItem onClick={() => logoutUser(logout)}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
