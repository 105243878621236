import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Stepper, Step, StepLabel, Typography } from "@material-ui/core";
import { useVerificationHook } from "./steps.hook";
import { TransitionStepComponent } from "./transition-step.component";
import { ProcessingStepComponent } from "./processing-step.component";
import { ConfirmationStepComponent } from "./confirmation-step.component";

const useStyles = makeStyles((theme) => ({
  layoutCenter: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
}));

export default function VerificationPage() {
  const classes = useStyles();
  const [verificationContext] = useVerificationHook();
  const { STEPS, activeStep } = verificationContext;

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <TransitionStepComponent context={verificationContext} />;
      case 1:
        return <ProcessingStepComponent context={verificationContext} />;
      case 2:
        return <ConfirmationStepComponent context={verificationContext} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Paper className={classes.layoutCenter}>
        <Typography component="h1" variant="h4" align="center">
          Network Transition Tool
        </Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {STEPS.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent(activeStep)}
      </Paper>
    </>
  );
}
