import React from "react";
import { Route, Switch } from "react-router-dom";
// import DashboardPage from "../pages/dashboard/dashboard.page";
import VerificationPage from "../pages/verification/verification.page";
// import { DevicesPage } from "../pages/devices/devices.page";

export default function AppPages() {
  return (
    <Switch>
      {/* <Route path="/" exact>
        <DashboardPage />
      </Route> */}
      <Route path="/" exact>
        <VerificationPage />
      </Route>
      {/* <Route path="/devices" exact>
        <DevicesPage />
      </Route> */}
    </Switch>
  );
}
