import React, { useState } from "react";
import { useEffect } from "react";
import { green } from "@material-ui/core/colors";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTransitionHook } from "./transition-step.hook";
import { useProcessingStepHook } from "./steps.hook";
import { ErrorDialogComponent } from "../../components/error-dialog.component";

const useStyles = makeStyles((theme) => ({
  checkCircleColor: {
    fill: green[500],
  },
  alignCenter: {
    textAlign: "left",
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
}));

export function ProcessingStepComponent({ context }) {
  const classes = useStyles();
  const { onNextStep, locationCode } = context;
  const [processingErrors, setProcessingErrors] = useState(null);
  const [processingStepContext] = useProcessingStepHook();
  const { PSTEPS, activePStep, onNextPStep } = processingStepContext;
  const [{ transitionDevice }] = useTransitionHook();

  useEffect(() => {
    console.log("Only when component mounts");

    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    const startNetworkTransition = async () => {
      for (let taskID = 0; taskID < PSTEPS.length; taskID++) {
        try {
          console.log(
            `This is Task ID: ${taskID} for locationCode: ${locationCode}`
          );
          await transitionDevice({ locationCode, taskID });
          await sleep(2000);
          onNextPStep(taskID);
        } catch (e) {
          // check for some errors and perhaps display to the user.
          console.log("error: ", e);
          const errorMessage = `An error occurred while transitioning the device: ${e.message}`;

          if ((taskID = 1)) {
            console.log(`Error 1: ${e}`);
            setProcessingErrors(errorMessage);
            return;
          } else if ((taskID = 2)) {
            console.log(`Error 2: ${e}`);
            setProcessingErrors(errorMessage);
            return;
          } else if ((taskID = 3)) {
            console.log(`Error 3: ${e}`);
            setProcessingErrors(errorMessage);
            return;
          } else {
          }
        }
      }

      sleep(1000);
      onNextStep();
    };

    startNetworkTransition();

    // eslint-disable-next-line
  }, []);

  const renderProcessingStepContent = (pStep) => {
    switch (pStep) {
      case 0:
        return (
          <div>
            <Typography
              component="p"
              variant="subtitle1"
              align="left"
              style={{ marginTop: 20 }}
            >
              Updating Screens...
            </Typography>
          </div>
        );
      case 1:
        return (
          <div>
            <Typography
              component="p"
              variant="subtitle1"
              align="left"
              style={{ marginTop: 20 }}
            >
              Updating Media Players...
            </Typography>
          </div>
        );
      case 2:
        return (
          <div>
            <Typography
              component="p"
              variant="subtitle1"
              align="left"
              style={{ marginTop: 20 }}
            >
              Changing New Prime Virtual IP...
            </Typography>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <Box flexDirection="column" alignItems="center" justifyItems="center">
      <div className={classes.alignCenter}>
        <Typography variant="h5" gutterBottom>
          Processing Update
        </Typography>

        <Stepper
          activeStep={activePStep}
          className={classes.stepper}
          orientation="vertical"
        >
          {PSTEPS.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {renderProcessingStepContent(activePStep)}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
      <ErrorDialogComponent
        isOpen={processingErrors !== null}
        title="Error"
        content={processingErrors}
        closeHandler={() => setProcessingErrors(null)}
      />
    </Box>
  );
}
