import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import jwt from "jsonwebtoken";
import { getReturnToUrl, setUserDetails } from "../helper";
import { createBrowserHistory } from "history";
import { Config } from "../helper/config.helper";

const onRedirectCallback = (appState) => {
  createBrowserHistory().push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, config, ...initOptions }) => {
  const [auth0Client, setAuth0] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      if (window.location.search.includes("code=")) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      console.log("isAuthenticated: ", isAuthenticated);
      if (!isAuthenticated) {
        auth0FromHook.loginWithRedirect({});
      }

      const user = await auth0FromHook.getUser();
      const token = await auth0FromHook.getTokenSilently();
      const decodedToken = jwt.decode(token);
      const roleUrl = `${config.audience}/sbls/role`;
      const userRole = decodedToken[roleUrl];

      const isAuthorized = Config.permittedLSRoles.includes(userRole);
      console.log("isAuthorized: ", isAuthorized);
      if (!isAuthorized) {
        auth0FromHook.logout({ returnTo: getReturnToUrl() });
        return;
      }

      const userWithRole = { role: userRole, ...user };
      setUserDetails(userRole, token);
      setUser(userWithRole);
      setLoading(false);
    };

    initAuth0();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Auth0Context.Provider
      value={{
        loading,
        user,
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
