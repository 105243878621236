import { getReturnToUrl } from "./index";
import jwt from "jsonwebtoken";

export function setUserDetails(userRole, token) {
  localStorage.setItem("role", userRole);
  localStorage.setItem("token", token);
}

export function clearUserDetails() {
  localStorage.removeItem("role");
  localStorage.removeItem("token");
  localStorage.removeItem("CLIENT_CODE");
}

export function getDecodedToken() {
  const token = localStorage.getItem("token");
  if (!token) {
    return null;
  }
  return jwt.decode(token);
}

export function checkIfTokenExpired() {
  const decodedToken = getDecodedToken();
  if (!decodedToken) {
    return true;
  }

  const { exp } = decodedToken;
  const tokenExpiration = new Date(exp * 1000);
  const dateNow = new Date();

  if (dateNow > tokenExpiration) {
    return true;
  }
  return false;
}

export function logoutUser(logoutFn) {
  clearUserDetails();
  logoutFn({ returnTo: getReturnToUrl() });
}
